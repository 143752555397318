import * as $ from 'jquery';
import 'modaal';

declare global {
    interface JQuery {
        modaal(arg: any): JQuery;
    }
}

export function Render(panelId: string, cookieId: string, videoOnly: boolean = false, resetSession: boolean = false) {
    if ($(`#Panel${panelId}`).find('.cmsPanelContent').length) {
        let panelContentLength = $(`#Panel${panelId}`).find('.cmsPanelContent').text().trim().length;
        let data = sessionStorage.getItem(cookieId);
        if (videoOnly) {
            $(`#Panel${panelId} .cmsPanelContent p`).contents().unwrap();
        }
        if (!data && !resetSession &&(panelContentLength > 0 || videoOnly )) {
            $(`#Panel${panelId}`).modaal({
                content_source: `#Panel${panelId}`,
                start_open: true,
                after_close: function () {
                    if (!resetSession) {
                        sessionStorage.setItem(cookieId, 'true');
                    }
                }
            });
        }
        if (!data && resetSession &&(panelContentLength > 0 || videoOnly )) {
            $(`#Panel${panelId}`).modaal({
                content_source: `#Panel${panelId}`,
                start_open: true,
                overlay_close: true,
                after_close: function () {
                    resetSession= true
                        $('.assessBtn').each(function() {
                            $(this).on('click', function() {
                             //    open the modal on every click
                                $(`#Panel${panelId}`).modaal('open');
                            });
                         });
                         let bariatricSection = document.querySelectorAll('.bariatricPop');
                         bariatricSection[0].setAttribute('style', 'display:none')
                },
                before_close: function () {
                    resetSession= true
                    
                        sessionStorage.removeItem(cookieId);
                    
                }
            });
        }
        
    } else {
        console.error('Panel does not exist on the page');
    }
}



